import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["chatInput"]; // Define chatInput as a target

  connect() {
    console.log("Message input controller connected!");

    // Set focus on the element when the controller connects
    this.element.focus();
  }

  resize() {
    const textarea = this.chatInputTarget;

    // Reset the height to recalculate content height
    textarea.style.height = "auto";

    // Maximum lines to allow before scrollbars
    const maxLines = 4;

    // Get line height from computed style, with a fallback
    const computedStyle = getComputedStyle(textarea);
    const lineHeight =
      parseFloat(computedStyle.lineHeight) || 16; // Fallback to 16px if lineHeight is "normal"

    const maxHeight = maxLines * lineHeight;

    // Adjust the textarea height based on content, but not exceeding maxHeight
    textarea.style.height = `${Math.min(textarea.scrollHeight, maxHeight)}px`;
  }
}
